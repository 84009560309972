<template>
   <main>
      <slide />

      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 grey-bg">
         <div class="container">
            <div class="breadcrumb__wrapper">
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0">
                     <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                     <li class="breadcrumb-item active" aria-current="page">Categorias</li>
                  </ol>
               </nav>
            </div>
         </div>
      </section>

		<!-- categorias area start -->
      <section class="categorias__area pt-20 grey-bg" :class="pesquisa.resultado != null && pesquisa.resultado.subcategorias.length > 0 ? 'pb-30' : 'pb-200'">
         <div class="container">
            <div class="cards-md cards-xs" v-if="pesquisa.resultado != null && pesquisa.resultado.categorias.length > 0">
               <categoria v-for="(categoria, index) in pesquisa.resultado.categorias" :key="index" :categoria="categoria" :page="'Categorias'" />
            </div>
				<div v-if="pesquisa.resultado == null || pesquisa.resultado.categorias.length == 0" class="mb-200">Nenhuma categoria encontrada</div>
         </div>
      </section>

		<!-- categorias area start -->
      <section class="categorias__area pt-30 pb-200 grey-bg" v-if="pesquisa.resultado != null && pesquisa.resultado.subcategorias.length > 0">
         <div class="container">
            <div class="section__head mb-30">
               <div class="w-100 section__title">
                  <h3 class="text-center mb-1">Todos os produtos</h3>
               </div>
            </div>
            <div class="cards-xs cards" v-if="pesquisa.resultado != null && pesquisa.resultado.subcategorias.length > 0">
					<subcategoria v-for="(subcategoria, index) in pesquisa.resultado.subcategorias" :key="index" :subcategoria="subcategoria" :page="'Categorias'" />
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import { mapState } from 'vuex'
import slide from '@/components/Slide.vue'
import categoria from '@/components/home/Categoria.vue'
import subcategoria from '@/components/home/Subcategoria.vue'

export default {
   name: 'Categorias',
   data : function () {
      return {
         pesquisa: {'resultado': null}
      }
   },
   computed: {
		... mapState({
			urlRest: state => state.urlRest
		})
	},
   components: {
      slide, categoria, subcategoria
   },
   methods: {
      getCategorias : function () {
         let ref = this;
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest + 'site/getCategorias'
            
			}).then(response => {
            this.pesquisa.resultado = response.data

			}).catch(function (error) {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						ref.$store.dispatch('deslogar')

						ref.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						ref.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					ref.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      }
   },
   mounted() {
      this.getCategorias()
   }
}

</script>